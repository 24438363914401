import React from 'react'

const Logo = (props) => (
  <svg width="118" height="118" viewBox="0 0 118 118" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M17.6907 47.7301C22.0302 47.7301 25.5481 44.1995 25.5481 39.8442C25.5481 35.4889 22.0302 31.9583 17.6907 31.9583C13.3512 31.9583 9.83334 35.4889 9.83334 39.8442C9.83334 44.1995 13.3512 47.7301 17.6907 47.7301Z" fill="white"/>
    <path d="M17.6907 50.8044C22.3149 50.8044 24.7641 53.6315 24.7641 57.7399V81.0182C24.7641 85.1266 22.3149 87.9537 17.6907 87.9537C13.0526 87.9537 10.6173 85.1266 10.6173 81.0182V57.7399C10.6208 53.6315 13.0526 50.8044 17.6907 50.8044Z" fill="white"/>
    <path d="M54.1851 60.7166L59.9541 54.9127L47.018 41.8948C43.8577 38.716 38.7305 38.7091 35.5598 41.8808C32.3926 45.0526 32.3856 50.1985 35.5459 53.3807L53.6196 71.5689C51.0456 68.3762 51.2329 63.6864 54.1851 60.7166Z" fill="#4265FF"/>
    <path d="M84.3449 41.8808C81.1777 38.7091 76.047 38.716 72.8867 41.8948L59.9507 54.9127L65.7197 60.7166C68.6718 63.6864 68.8591 68.3762 66.2851 71.5654L84.3588 53.3772C87.5191 50.1985 87.5122 45.0526 84.3449 41.8808Z" fill="#28C2A5"/>
    <path d="M65.7196 60.717L59.9506 54.9131L54.1851 60.717C51.2329 63.6868 51.0456 68.3766 53.6196 71.5658L54.251 72.1995C55.8259 73.7836 57.89 74.5775 59.9541 74.5879C62.0216 74.5809 64.0823 73.7836 65.6572 72.1995L66.2886 71.5658C68.8591 68.3766 68.6718 63.6868 65.7196 60.717Z" fill="#0D4AAC"/>
    <path d="M75.5647 65.2603C74.2569 66.5694 71.9742 69.0693 71.9742 74.3962C71.9742 78.3583 71.9742 80.2384 71.9742 80.2384C71.9742 84.2458 75.2872 87.9537 79.28 87.9537H79.3355C83.3284 87.9537 86.7246 84.2458 86.7246 80.2384V54.2305C86.7246 54.2305 76.8725 63.9512 75.5647 65.2603Z" fill="white"/>
    <path d="M44.3399 65.2603C45.6477 66.5694 47.9304 69.0693 47.9304 74.3962C47.9304 78.3583 47.9304 80.2384 47.9304 80.2384C47.9304 84.2458 44.7319 87.9537 40.7425 87.9537H40.687C36.6942 87.9537 33.18 84.2458 33.18 80.2384V54.2305C33.18 54.2305 43.0321 63.9512 44.3399 65.2603Z" fill="white"/>
    <path d="M106.241 87.1462H97.0657C96.0007 87.1462 95.1404 86.2793 95.1404 85.2139V53.5483C95.1404 52.4794 96.0042 51.616 97.0657 51.616H106.241C107.306 51.616 108.167 52.4829 108.167 53.5483V85.2139C108.167 86.2793 107.306 87.1462 106.241 87.1462Z" fill="white"/>
    <path d="M106.141 46.3827H97.1663C96.0458 46.3827 95.1404 45.4705 95.1404 44.3494V35.3424C95.1404 34.2178 96.0493 33.3091 97.1663 33.3091H106.141C107.261 33.3091 108.167 34.2213 108.167 35.3424V44.3494C108.167 45.4705 107.261 46.3827 106.141 46.3827Z" fill="white"/>
  </svg>
)

export default Logo
