import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Navbar from "./Navbar";
import Seo from "./Seo";
import Footer from "./Footer";
import './i18n';

const Layout = ({ children, seo, navbarTheme = "dark" }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          strapiHomepage {
            seo {
              metaTitle
              metaDescription
              shareImage {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <div>
          <Seo seo={seo} />
          <Navbar theme={navbarTheme} />
          <main className="min-h-screen overflow-x-hidden">{children}</main>
          <Footer />
        </div>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
