export const routes = [
  {
    id: 1,
    path: "/",
    // text: "Home",
    key: 'menu.home',
  },
  {
    id: 2,
    path: "/about-us",
    // text: "About Us",
    key: 'menu.aboutUs',
  },
  {
    id: 3,
    path: "/product/",
    // text: "Product",
    key: 'menu.product',
  },
  {
    id: 4,
    path: "/career",
    // text: "Career",
    key: 'menu.career',
  },
  {
    id: 5,
    path: "/contact/",
    // text: "Contact",
    key: 'menu.contact',
  },
];
