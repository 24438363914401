import React, { useEffect, useState } from "react";

import "./styles.css";

const BurgerIcon = ({ onClick, collapsed, color = "bg-navy-400" }) => {
  const [spanColor, setSpanColor] = useState("bg-navy-400");

  useEffect(() => {
    setSpanColor(collapsed ? "bg-navy-400" : color);
  }, [collapsed, color]);

  return (
    <button
      type="button"
      onClick={onClick}
      className={collapsed ? "burger nav-open" : "burger"}
    >
      <span className={spanColor} />
      <span className={spanColor} />
      <span className={spanColor} />
    </button>
  );
};

export default BurgerIcon;
