import React, {useState} from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'

import Logo from '@assets/img/logo-v2'
import GlobalMap from '@assets/img/global-map.png'
import Facebook from '@assets/img/facebook.png'
import Instagram from '@assets/img/instagram.png'
import Linkedin from '@assets/img/Linkedin.png'
// import FooterMobile from '@assets/img/footer-mobile.png'
import Twitter from '@assets/img/twitter-icon.png'
import {sendSubscriber} from "../action";
import { useTranslation } from 'react-i18next'

const query = graphql`
  query {
    strapiGlobal {
      footer {
        information {
          id
          label
          content
        }
        usefulLink {
          id
          label
          content
        }
        socialNetwork {
          id
          label
          content
        }
      }
    }
  }
`

export const Footer = () => {
  const { t } = useTranslation()

  const handleClickSubscribe = () => {
    if (!isEmail(emailSub)) {
      setMsg({content: 'error.invalidEmail',type: 'err'});
    } else {
      sendSubscriber({email: emailSub})
          .then(resp=>{
            var rs = resp.data;
            if (rs.error.code == 1) {
              setMsg({content: 'footer.subcribe.success', type: 'success'});
            } else {
              console.log(rs);
              if (rs.error && rs.error.msg) {
                setMsg({content: rs.error.msg, type: 'err'});
              } else {
                setMsg({content: 'footer.subcribe.error', type: 'err'});
              }
            }
          })
    }
  }
  const isEmail = (str) => {
    return new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/).test(str)
  }
  const emailSubscriber = "";
  const [emailSub, setEmailSub] = useState(emailSubscriber);
  const message={
    type:"",
    content:""
  }
  const [msg, setMsg] = useState(message);
  return (
    <StaticQuery
      query={query}
      render={(data) => (
        <footer
          className="text-white bg-global-map bg-no-repeat bg-center bg-cover mt-[10px]"
        >
          <div className="container pt-16 pb-[140px]">
            <div className="grid md:grid-cols-12">
              <div className="col-span-5 md:order-none sm:pl-[15px] sm:mt-[2px]  order-3 mt-[43px]">
                <Link to="/">
                  <Logo className="pointer-events-none" />
                </Link>
                {/* <p className="font-bold text-base mt-[32px]">
                  A small Sillicon Valley replicable in every part of the world
                </p> */}
                <ul className="flex flex-col gap-2">
                  {/* {data.strapiGlobal.footer.information.map((i) => (
                    <li key={i.id} className="sm:my-[13px]  sm:text-base my-[8px]">
                      <span>{i.label}: </span>
                      <span>{i.content}</span>
                    </li>
                  ))} */}
                  <li className="sm:text-base">
                    <span>Email: </span>
                    <a href="mailto:contact@imi.ai">contact@imi.ai</a>
                  </li>
                  <li className="sm:text-base">
                    <span>{t('footer.phone')} (US): </span>
                    <a href="tel:14154971516">1-415-497-1516</a>
                  </li>
                  <li className="sm:text-base">
                    <span>{t('footer.phone')} (VN): </span>
                    <a href="tel:+84909499341">+84 909499341</a>
                  </li>
                </ul>
              </div>

              <div className="col-span-2 sm:mt-[25px] sm:pl-[12px]">
                <p className="sm:text-xl font-bold text-base mt-[-16px]">{t('footer.link.title')}</p>
                <ul className="mt-[30px] flex flex-col gap-5">
                  <li className="text-base">
                    <Link to={"/about-us"}>{t('menu.aboutUs')}</Link>
                  </li>
                  <li className="text-base">
                    <Link to={'/product'}>{t('menu.product')}</Link>
                  </li>
                  <li className="text-base">
                    <Link to={'/career'}>{t('menu.career')}</Link>
                  </li>
                  <li className="text-base">
                    <Link to={'/contact'}>{t('menu.contact')}</Link>
                  </li>
                </ul>
              </div>

              <div className="col-span-5 sm:mt-[-23px] sm:pl-[12px]">
                <p className="sm:text-xl font-bold text-base mt-[31px]">{t('footer.subcribe.title')}</p>
                <p className="font-thin text-sm mt-[30px]">{t('footer.subcribe.content1')}</p>
                <div className="rounded-full flex overflow-hidden relative input-footer mt-[15px]">
                  <input
                    className="bg-white w-[90%] lg:w-[90%] md:w-[75%] h-[44px] pl-[27px] text-navy"
                    placeholder={t('footer.subcribe.placeholder1')}
                    onChange={(e)=>{setEmailSub(e.target.value)}}
                  />
                  <button
                    className="sm:right-[13px] font-bold text-base capitalize bg-blue-700 absolute w-[120px] h-[44px] right-0 rounded-tl-[36px] rounded-bl-[36px] rounded-tr-[36px] rounded-br-[36px]"
                    onClick={handleClickSubscribe}
                  >{t('footer.subcribe.cta1')}</button>
                </div>{' '}
                <div className={`text-left mt-[24px]  ${msg.type=='err'?"text-red-400":"text-green-400"}`}>
                  {msg.type==='err'&&
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                    </svg>
                  }
                  {msg.type==='success'&&
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                    </svg>
                  }
                  {
                    <p className="inline-block ml-[8px]">{t(msg.content)}</p>
                  }
                </div>

                <div className="flex sm:mt-[35px] mt-[47px] items-center ">
                  <span className="sm:text-xl font-bold text-lg  mr-2">{t('footer.follow.title')}</span>
                  <ul className="flex">
                    <li className="sm:ml-[6px] ml-[15px] cursor-pointer">
                      <a href="https://www.facebook.com/imihealth" target="_blank" rel="noopener noreferrer">
                        <img src={Facebook} />
                      </a>
                    </li>
                    <li className="mx-[10px] cursor-pointer">
                      <a href="https://www.linkedin.com/company/imihealth" target="_blank" rel="noopener noreferrer">
                        <img src={Linkedin} />
                      </a>
                    </li>
                    <li className=" cursor-pointer">
                      <a href="https://twitter.com/imi4health" target="_blank" rel="noopener noreferrer">
                        <img src={Twitter} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <p className="mt-[20px] sm:text-base sm:pl-[15px]">
              &copy; {t('footer.copyright')}
            </p>
          </div>
        </footer>
      )}
    />
  )
}

export default Footer
