import React from "react";

const Button = ({ children, size = "sm", className = "", ...rest }) => {
  const baseStyle =
    "rounded-full font-bold text-white bg-blue-700 hover:shadow-blue-400 active:shadow-blue duration-300 flex justify-center items-center";

  const buttonStyleBySize =
    {
      sm: "py-2 px-3",
      lg: "text-base py-[10px] px-6 sm:tracking-tight sm:text-xl sm:py-4 sm:px-20",
      xl: "tracking-tight text-xl py-4 px-6",
    }[size] || "";

  return (
    <button
      className={[baseStyle, buttonStyleBySize, className].join(" ")}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
