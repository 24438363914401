import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import transEn from '@locales/en/translation';
import transVi from '@locales/vi/translation';
import homePageEn from '@locales/en/homePage';
import homePageVi from '@locales/vi/homePage';
import aboutUsEn from '@locales/en/aboutUs';
import aboutUsVi from '@locales/vi/aboutUs';
import productEn from '@locales/en/product';
import productVi from '@locales/vi/product';
import contactEn from '@locales/en/contact';
import contactVi from '@locales/vi/contact';

import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: transEn,
    homePage: homePageEn,
    aboutUs: aboutUsEn,
    product: productEn,
    contact: contactEn,
  },
  vi: {
    translation: transVi,
    homePage: homePageVi,
    aboutUs: aboutUsVi,
    product: productVi,
    contact: contactVi,
  },
}

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en',
    debug: true,

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;