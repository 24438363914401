import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Link } from 'gatsby'
import { routes } from '@configs'
import { BurgerButton, Button } from '@components/Button'

import Logo from '@assets/img/logo-v3'
import viIcon from '@assets/img/lang-vi.png'
import enIcon from '@assets/img/lang-en.png'
import './styles.css'

import { useTranslation } from 'react-i18next'

const MenuItems = (menus, t) => {
  return menus.map((route) => (
  <li key={route.id} className="item-navbar">
    <Link className="nav-item" to={route.path} activeClassName="active">
      <span className="text-base font-bold">{t(`${route.key}`)}</span>
    </Link>
  </li>
))}

function onClickOutside(element, callback = () => {}) {
  const outsideClickListener = event => {
      if (!element.contains(event.target) && isVisible(element)) { // or use: event.target.closest(selector) === null
        callback();
        removeClickListener();
      }
  }

  const removeClickListener = () => {
      document.removeEventListener('click', outsideClickListener);
  }

  document.addEventListener('click', outsideClickListener);
}
const isVisible = elem => !!elem && !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length );

const NavBar = ({ theme }) => {
  const isDark = theme === 'dark'
  const textColor = isDark ? 'text-navy' : 'text-navy-200'

  const navbarRef = useRef(null)
  const desktopMenuRef = useRef(null)

  const [collapsed, setCollapsed] = useState(false)
  const [scrollY, setScrollY] = useState(100)
  const [burgerColor, setBurgerColor] = useState(isDark ? 'bg-navy-400' : 'bg-white')

  const { t, i18n } = useTranslation()
  const setLang = ['en', 'vi']
  const [showMenu, setShowMenu] = useState(false)
  if (!setLang.includes(i18n.language.toLowerCase())) {
    i18n.changeLanguage(setLang[0]);
  }

  // Block body scrolling when the menu is open
  useEffect(() => {
    collapsed ? document.body.classList.add('overflow-hidden') : document.body.classList.remove('overflow-hidden')
  }, [collapsed])

  // Listen to scroll event to change navbar style
  useEffect(() => {
    scrollHandler()

    if (typeof window === 'object') {
      window.addEventListener('scroll', scrollHandler)
      return () => window.removeEventListener('scroll', scrollHandler)
    }
  }, [])
  const loginForm = 'https://app.imi.ai/'

  const openLink = (toppic) => {
    window.open(toppic)
  }
  const scrollHandler = useCallback(() => {
    // animation background header (slide down background element)
    setScrollY(window.scrollY < 50 ? 100 - window.scrollY.toFixed(0) * 2 : 0)

    if (window.scrollY === 0) {
      // set all effect to default
      setScrollY(100)
      setBurgerColor(isDark ? 'bg-navy-400' : 'bg-white')
      navbarRef.current?.classList.remove('shadow')
      desktopMenuRef.current?.classList.add(textColor)
      desktopMenuRef.current?.classList.remove('text-navy')
    } else if (window.scrollY < 25) {
      // set text to dark - cuz background is white
      desktopMenuRef.current?.classList.add(textColor)
      desktopMenuRef.current?.classList.remove('text-navy')
    } else if (window.scrollY < 50) {
      // remove header shadow
      navbarRef.current?.classList.remove('shadow')
      // set menu button to dark - cuz background is white
      setBurgerColor('bg-navy-400')
      // set text to theme color
      desktopMenuRef.current?.classList.remove(textColor)
      desktopMenuRef.current?.classList.add('text-navy')
    } else {
      // add header shadow
      navbarRef.current?.classList.add('shadow')
    }
  }, [])
  const changeLanguage = (lang = 'en') => {
    const val = `${lang}`.toLowerCase();
    setShowMenu(false)
    if (!setLang.includes(val)) {
      return
    }
    i18n.changeLanguage(val)
  }

  return (
    <div
      ref={navbarRef}
      className={
        'bg-transparent w-screen fixed z-40 top-0 py-3 h-[52px] sm:h-[82px] flex items-center ' +
        (scrollY > 50 ? theme : '')
      }
    >
      <div
        className="bg-white absolute top-0 w-full h-full duration-100 ease-out"
        style={{ transform: `translate(0, -${scrollY}%)` }}
      ></div>
      <div className="container bg-transparent relative">
        <nav className="bg-transparent flex justify-between items-center">
          <Link to="/">
            <Logo className="w-auto h-[36px] md:h-[62px] pointer-events-none" />
          </Link>

          <ul className="flex items-center xl:space-x-12 md:space-x-10">
            <div
              ref={desktopMenuRef}
              className={
                'hidden sm:flex items-center xl:space-x-12 md:space-x-6 ' +
                (theme === 'dark' ? 'text-navy' : 'text-navy-200')
              }
            >
              {MenuItems(routes, t)}
            </div>
            <li className={
              'relative flex items-center gap-1 mr-8 cursor-pointer ' + 
              ((theme === 'dark') || (scrollY < 50) ? 'text-navy' : 'text-navy-200')} onClick={(e) => { setShowMenu(!showMenu); if (!showMenu) onClickOutside(e.target, () => setShowMenu(false)); }}>
              {/* <img src={i18n.language === 'en' ? viIcon : enIcon} alt={i18n.language === 'en' ? 'vie' : 'eng'}/> */}
              <span className='font-bold pointer-events-none'>{i18n.language === 'vi' ? 'VIE' : 'ENG'}</span>
              <svg className='pointer-events-none' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M2.85908 5.60183C3.13864 5.2716 3.62022 5.24186 3.93472 5.53539L7.99996 9.32962L12.0652 5.53539C12.3797 5.24186 12.8613 5.2716 13.1408 5.60183C13.4204 5.93206 13.3921 6.43771 13.0776 6.73125L8.50614 10.9979C8.21747 11.2673 7.78245 11.2673 7.49378 10.9979L2.92236 6.73125C2.60786 6.43771 2.57953 5.93206 2.85908 5.60183Z" fill="#7790A6"/>
              </svg>
              <ul className={`absolute js-floating-menu floating-menu ${showMenu ? 'active' : ''}`}>
                <li className={`flex items-center py-3 px-4`} onClick={() => { changeLanguage('vi') }}>VIE</li>
                <li className={`flex items-center py-3 px-4`} onClick={() => { changeLanguage('en') }}>ENG</li>
              </ul>
            </li>
            <li>
              <Button className="bg-blue-400 mr-4 w-[90px] h-7 sm:h-11 sm:w-30 md:w-[120px]" onClick={() => openLink(loginForm)}>
                <span className="whitespace-nowrap">
                  {/* NOTE: change Login to Get Started in MVP version */}
                  {/* Login */}
                  {t('menu.login')}
                </span>
              </Button>
            </li>
            <li className="block sm:hidden pr-2" style={{ margin: 0 }}>
              <BurgerButton color={burgerColor} collapsed={collapsed} onClick={() => setCollapsed(!collapsed)} />

              {/* Menu overlay */}
              {collapsed && <div className="bg-black bg-opacity-70 top-0 left-0 right-0 bottom-0 fixed z-30" />}

              {/* Mobile menu */}
              <ul
                className={
                  'top-0 left-[20%] right-0 bottom-0 flex flex-col space-y-5 fixed items-center duration-300 bg-white z-30 sm:hidden mobile ' +
                  (collapsed ? 'translate-x-0' : 'translate-x-[100%]')
                }
              >
                <li className="pt-4 pb-6 text-navy text-base font-bold">MENU</li>
                {MenuItems(routes, t)}
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default NavBar
