import React from 'react'

const Logo = (props) => (
  <svg width="96" height="62" viewBox="0 0 96 62" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M14.3924 20.8314C17.9229 20.8314 20.7849 17.959 20.7849 14.4157C20.7849 10.8724 17.9229 8 14.3924 8C10.862 8 8 10.8724 8 14.4157C8 17.959 10.862 20.8314 14.3924 20.8314Z" fill="#C3C6C8"/>
    <path d="M14.3924 23.3324C18.1545 23.3324 20.147 25.6324 20.147 28.9748V47.9131C20.147 51.2555 18.1545 53.5555 14.3924 53.5555C10.619 53.5555 8.63782 51.2555 8.63782 47.9131V28.9748C8.64064 25.6324 10.619 23.3324 14.3924 23.3324Z" fill="#C3C6C8"/>
    <path d="M44.0828 31.3966L48.7762 26.6748L38.252 16.084C35.6809 13.4979 31.5096 13.4922 28.93 16.0726C26.3533 18.6531 26.3476 22.8395 28.9187 25.4285L43.6227 40.2256C41.5286 37.6282 41.681 33.8128 44.0828 31.3966Z" fill="#4265FF"/>
    <path d="M68.6196 16.0726C66.0429 13.4922 61.8687 13.4979 59.2976 16.084L48.7734 26.6748L53.4668 31.3966C55.8686 33.8128 56.021 37.6282 53.9269 40.2228L68.6309 25.4256C71.202 22.8395 71.1963 18.6531 68.6196 16.0726Z" fill="#28C2A5"/>
    <path d="M53.4668 31.3969L48.7734 26.6751L44.0828 31.3969C41.681 33.8131 41.5286 37.6285 43.6227 40.2231L44.1364 40.7386C45.4177 42.0274 47.0969 42.6732 48.7762 42.6817C50.4583 42.676 52.1347 42.0274 53.416 40.7386L53.9297 40.2231C56.021 37.6285 55.8685 33.8131 53.4668 31.3969Z" fill="#0D4AAC"/>
    <path d="M61.4763 35.0932C60.4123 36.1582 58.5553 38.192 58.5553 42.5258C58.5553 45.7492 58.5553 47.2787 58.5553 47.2787C58.5553 50.539 61.2506 53.5556 64.499 53.5556H64.5442C67.7926 53.5556 70.5556 50.539 70.5556 47.2787V26.1198C70.5556 26.1198 62.5403 34.0282 61.4763 35.0932Z" fill="#C3C6C8"/>
    <path d="M36.0732 35.0932C37.1372 36.1582 38.9942 38.192 38.9942 42.5258C38.9942 45.7492 38.9942 47.2787 38.9942 47.2787C38.9942 50.539 36.3921 53.5556 33.1465 53.5556H33.1013C29.8529 53.5556 26.9939 50.539 26.9939 47.2787V26.1198C26.9939 26.1198 35.0092 34.0282 36.0732 35.0932Z" fill="#C3C6C8"/>
    <path d="M86.4336 52.8986H78.9687C78.1023 52.8986 77.4023 52.1933 77.4023 51.3265V25.5647C77.4023 24.6951 78.1051 23.9926 78.9687 23.9926H86.4336C87.3 23.9926 88 24.6979 88 25.5647V51.3265C88 52.1933 87.3 52.8986 86.4336 52.8986Z" fill="#C3C6C8"/>
    <path d="M86.3518 19.7352H79.0505C78.139 19.7352 77.4023 18.9931 77.4023 18.081V10.7532C77.4023 9.83832 78.1418 9.09903 79.0505 9.09903H86.3518C87.2633 9.09903 88 9.84115 88 10.7532V18.081C88 18.9931 87.2633 19.7352 86.3518 19.7352Z" fill="#C3C6C8"/>
  </svg>  
)

export default Logo
